document.addEventListener("turbolinks:load", function() {
    const app = document.getElementById('app');
    if(app !== null) {
        setInterval(function() {addDollar(app)}, 200);
    }
})


function addDollar(app) {
    const span = document.createElement('SPAN');
    span.classList.add('dollar-container');
    span.style.fontSize = 20 + Math.random() * 40 + 'px';
    span.style.left = Math.random() * window.innerWidth + 'px';
    span.style.animationDuration = 1 + Math.random() * 3 + 's';

    const icon = document.createElement('I');
    icon.classList.add('fas','fa-dollar-sign');
    icon.style.animationDuration = 1 + Math.random() * 3 + 's';
    icon.style.color = '#85bb65';


    span.appendChild(icon);
    app.appendChild(span);

    setTimeout(() => {
        span.remove();
    }, 5000);
}

window.setBackgroundFromLocalStorage = function() {
    if(window.localStorage.getItem("sell2DealerCustomStyle") !== null) {
        const data = JSON.parse(window.localStorage.getItem("sell2DealerCustomStyle"));
        const bgOne = data.custom_style.background_gradient_one;
        const bgTwo = data.custom_style.background_gradient_two;
        if(bgOne !== '') {
            if(bgTwo !== '') {
                document.getElementById('gradient-header').style.backgroundImage = `linear-gradient(to bottom, ${bgOne}, ${bgTwo})`
            } else {
                document.getElementById('gradient-header').style.backgroundImage = `linear-gradient(to bottom, ${bgOne}, ${bgOne})`
            }
        } else {
            document.getElementById('gradient-header').style.backgroundImage = "linear-gradient(to bottom, #000000, #000000)"
        }
    } else {
        document.getElementById('gradient-header').style.backgroundImage = "linear-gradient(to bottom, #000000, #000000)"
    }
}

window.setFontFromLocalStorage = function() {
    if(window.localStorage.getItem("sell2DealerCustomStyle") !== null) {
        const data = JSON.parse(window.localStorage.getItem("sell2DealerCustomStyle"));
        const fontColor = data.custom_style.font_color;
        if(fontColor !== '') {
            updateAllTextFontColor(fontColor);
        } else {
            updateAllTextFontColor('white');
        }
    } else {
        updateAllTextFontColor('white');
    }
}