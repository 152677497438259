import {hexToCSSFilter} from 'hex-to-css-filter';
import Cookies from 'js-cookie'


document.addEventListener("turbolinks:load", function () {


    let plateElement = document.getElementById('properties_instant_range_license_plate')

    if(plateElement) {
        plateElement.addEventListener("focusout", function(event) {
            let plate = event.target.value;

            console.log(plate);
            if(plate.length > 3) {

                let data = {
                    'formId': '#instant-range-car-data-form',
                    'propertiesStateId': '#properties_instant_range_state',
                    'carInfoColDivId': '#instant-range-car-info-col-div',
                    'carInfoDivId': '#instant-range-car-info-div',
                    'carDataSubmitButtonId': '#instant-range-car-data-submit-button',
                }
                checkLicensePlateInfo(data, plate);
            }
        })
    }
})

window.referredByNext = function () {
    const data = {
        'formId': '#instant-range-car-data-form',
        'propertiesPlateId': '#properties_instant_range_license_plate',
        'propertiesStateId': '#properties_instant_range_state',
        'carInfoColDivId': '#instant-range-car-info-col-div',
        'carInfoDivId': '#instant-range-car-info-div',
        'carDataSubmitButtonId': '#instant-range-car-data-submit-button',
    }

}

window.showIssuesBox = function () {
    $('#issues-details-div').collapse('show');
    return $('#issues').prop("required", true);
};

window.hideIssuesBox = function () {
    $('#issues-details-div').collapse('hide');
    return $('#issues').prop("required", false);
};

window.showNameDiv = function (instantRangeBool) {
    if (instantRangeBool) {
        $('#instant-range-referred-check-div').collapse('show');
        $('#properties_instant_range_referred_name').prop("required", true);
        referredByNext()
    }
};

window.hideNameDiv = function (instantRangeBool) {
    if (instantRangeBool) {
        $('#instant-range-referred-check-div').collapse('hide');
        $('#properties_instant_range_referred_name').prop("required", false);
        referredByNext();
    }
};


window.saveToLocalStorage = function (key, data) {
    window.localStorage.setItem(key, JSON.stringify(data))

}

// What to do when we click translations
function translationToggle() {

    translationToggleUpdateText.bind(this)();
    updateAllTextFontColor(JSON.parse(window.localStorage.getItem("sell2DealerFontColor")));
    translationToggleSetValue.bind(this)();
}

// change form value so that we know
function translationToggleSetValue() {
    let elem = document.getElementById('properties_in_spanish');
    if(elem !== null) {
        elem.value = this.checked;
    }
    elem = document.getElementById('properties_instant_range_in_spanish');
    if(elem !== null) {
        elem.value = this.checked;
    }
}


function translationToggleUpdateText() {
    let translations;
    let dealerName = JSON.parse(window.localStorage.getItem("sell2DealerDealerName"))
    if (this.checked) {
        translations = window.localStorage.getItem("sell2DealerTranslationsSpanish")
    } else {
        translations = window.localStorage.getItem("sell2DealerTranslationsEnglish")
    }

    translations = JSON.parse(translations);

    for (const key in translations) {
        for (const key2 in translations[key]) {
            let innerHTML;
            if ([ "properties_instant_range_referred_name", "properties_instant_range_license_plate"].includes(key2)) {
                let element = document.getElementById(key2);
                if (element !== null) {
                    element.placeholder = translations[key][key2];
                }
                continue;
            } else if (key2 === "instant-range-agreement-label") {
                if(this.checked) {
                    //spanish
                     jQuery('.disclaimer-text').html("<p>Al marcar esta casilla, autoriza a " + dealerName + " a enviarle mensajes de texto. Se pueden aplicar tarifas de texto estándar. Con servicio de \n" +
                        "                        <a class=\"disclaimer-hyperlink\" href=\"https://textingdealer.com\"> TextingDealer</a>.\n" +
                        "                        El uso está sujeto a  <a class=\"disclaimer-hyperlink\" href=\"https://textingdealer.com/terms-of-use\">términos</a></p>")
                } else {
                    jQuery('.disclaimer-text').html("<p>By checking this box you authorize " + dealerName + " permission to text you. Standard text rates may apply. Serviced by\n" +
                        "                        <a class=\"disclaimer-hyperlink\" href=\"https://textingdealer.com\"> TextingDealer</a>.\n" +
                        "                        Use is subject to <a class=\"disclaimer-hyperlink\" href=\"https://textingdealer.com/terms-of-use\">terms</a></p>")
                }
            } else {
                innerHTML = translations[key][key2];
            }

            if (document.getElementById(key2) && !(key2 === "agreement-label" || key2 === "instant-range-agreement-label")) {
                document.getElementById(key2).innerHTML = innerHTML;
            }
        }
    }
}

// check if there is plate data and send query
function checkLicensePlateInfo(data, license_plate) {
    // we remove the form to show that we've already looked it up successfully
    if ($(data['formId'])[0] !== undefined) {
        license_plate = license_plate.replace(/[^a-z0-9]/gi, '')
        if (license_plate.length > 0) {
            requestCarData('license_plate', {
                '#license_plate': license_plate,
                '#state': $(data['propertiesStateId']).val(),
                ...data,
            })
        }
    }
}

// we need to show the loader, setup the hidden form, click the button
function requestCarData(type, data) {
    const carInfoColDiv = $(data['carInfoColDivId']);
    carInfoColDiv.append("<div class='loader'></div>");
    $(data['carInfoDivId']).collapse('show')

    $('#type', data['formId']).val(type)

    for (const [key, value] of Object.entries(data)) {
        $(key, data['formId']).val(value);
    }
    $(data['carDataSubmitButtonId']).click();
}

window.sharedLinkInstantRange = function(referred_name){
    document.addEventListener("turbolinks:load", function () {
        document.getElementById("properties_instant_range_referred_bool_yes").checked = true;
        showNameDiv(true)
        $('#properties_instant_range_referred_name').val(referred_name)
    });
}

async function queryAPIModel(make, query) {
    const where = encodeURIComponent(JSON.stringify({
        "Make": make,
        "Model": {
            "$regex": `(?i:${query}.*)`
        }
    }));
    const response = await fetch(
        `https://parseapi.back4app.com/classes/Carmodels_Car_Model_List?limit=10&keys=Model&where=${where}`,
        {
            headers: {
                'X-Parse-Application-Id': '6MKFNw41dHmotqs3Nx8gGCmuIPHLKHZsL12LZ3he', // This is your app's application id
                'X-Parse-REST-API-Key': 'VOHYrNxoIkCK0yP7J5NjgLrzQlOWwI7O3HBTVFS0', // This is your app's REST API key
            }
        }
    );
    const data = await response.json(); // Here you have the data that you need
}

window.updateLicensePlateNames = function(plate){
    if(plate === '') return;
    $('#properties_instant_range_license_plate').val(plate);
}