window.updateAllTextFontColor = function(hex) {
    let all, i, max, results;
    all = document.querySelectorAll('div,strong,p,li,h1,h2,h3,span, small, label, a, td,tr,th, button, input');
    i = 0;
    max = all.length;
    results = [];
    while (i < max) {
        all[i].style.color = hex;
        results.push(i++);
    }
    return results;
};
